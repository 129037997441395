// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('trix')
require('@rails/actiontext')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import '../stylesheets/application';
import * as fontSizeComponent from './components/font_size_change';
import * as fixedTopButtonComponent from './components/page_top';

// ページ読み込み時の処理
document.addEventListener('turbolinks:load', function() {
  // フォントサイズ変更のアクション
  fontSizeComponent.setChangefFontSize();
  // ページ上部に移動するページ内リンク
  fixedTopButtonComponent.scrollTop();
  // クリックイベント
  documentClickEvents();
  // ナビバーのアクション
  navbarActions();
})

// クリックイベント
export function documentClickEvents () {
   document.body.onclick = function (event) {

    // ナビバーのリンクが外れた時の対応(safari対応)
    let buttonNavLinkElement = void(0);
    let navActiveItemElement = document.querySelector('header nav ul#main-nav li.nav-item.active');
    let showCollapseElement = document.querySelector('header nav ul#main-nav li.nav-item div.collapse.d-block');
    if (!!navActiveItemElement || !!showCollapseElement) {
      if (!!navActiveItemElement) {
        buttonNavLinkElement = navActiveItemElement.querySelector('button.nav-link');
      }
      if (!buttonNavLinkElement) {
        buttonNavLinkElement = showCollapseElement.closest('li.nav-item').querySelector('button.nav-link');
      }

      if (!!buttonNavLinkElement) {
        if (event.target != buttonNavLinkElement && !event.target.closest('ul#main-nav')) {
          if (!!showCollapseElement) {
            showCollapseElement.classList.remove('d-block');
            showCollapseElement.classList.add('d-none');
          }
          if (!!navActiveItemElement) {
            navActiveItemElement.classList.remove('active');
          }
        }
      }
    }
  }
}

// ナビバーのアクション
export function navbarActions () {
  // ナビバーのリンクを取得
  let navItemElements = document.querySelectorAll('header nav ul#main-nav li.nav-item');

  if (!!navItemElements.length) {
    for (var i = 0; i < navItemElements.length; i++) {
      // ナビバーのリンクのクリックイベント
      navItemElements[i].addEventListener('click', function(event) {
        if (event.target.type == 'button') {
          for (var i = 0; i < navItemElements.length; i++) {
            if (this != navItemElements[i]) {
              if (navItemElements[i].classList.contains('active')) {
                navItemElements[i].classList.remove('active');
                var navItemElement = navItemElements[i].querySelector('div.collapse')

                if (!!navItemElement) {
                  if (navItemElement.classList.contains('d-none')) {
                    navItemElement.classList.remove('d-none');
                    navItemElement.classList.add('d-block');
                  } else {
                    navItemElement.classList.add('d-none');
                    navItemElement.classList.remove('d-block');
                  }
                }
              }
            }
          }

          var navItemElement = this.querySelector('div.collapse')
          if (this.classList.contains('active')) {
            if (!!navItemElement) {
              navItemElement.classList.remove('d-block');
              navItemElement.classList.add('d-none');
              this.classList.remove('active');
            }
          } else {
            if (!!navItemElement.classList.contains('d-block')) {
              this.classList.remove('active');
              navItemElement.classList.add('d-none');
              navItemElement.classList.remove('d-block');
            } else {
              this.classList.add('active');
              navItemElement.classList.remove('d-none');
              navItemElement.classList.add('d-block');
            }
          }
        }
      });
    }
  }
}