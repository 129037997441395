export function setChangefFontSize() {
  // クッキーから選択されたフォントサイズ情報を取得
  let selectedFontSizeElementId = getCookieFontSize();
  let target = document.getElementById(selectedFontSizeElementId);
  if (!!target) {
    // フォントサイズを変更
    changeFontSize(target);
  }

  let siteFontSizeChangeElements = document.querySelectorAll('header .site-font-size-change-area span.site-font-size-change');
  if (!!siteFontSizeChangeElements.length) {
    for (let i = 0; i < siteFontSizeChangeElements.length; i++) {
      siteFontSizeChangeElements[i].addEventListener('click', function(event) {
        changeFontSize(siteFontSizeChangeElements[i]);
      })
    }
  }
}

// フォントサイズを変更する処理
function changeFontSize(target) {
  if (!!target) {
    // セットするフォントサイズを取得
    let size = target.dataset.size;
    // bodyのスタイルにフォントサイズをセット
    document.querySelector('body').style.fontSize = size;
    // 選択中のスタイルを適用
    let element = document.querySelector('header .site-font-size-change-area span.site-font-size-change.active');
    if (!!element) {
      element.classList.remove('active');
    }
    target.classList.add('active');
    // クッキーに選択されたフォントサイズをセット
    document.cookie = 'fontSize=' + target.id + '; path=/;';
  }
}

// クッキーから選択されたフォントサイズ情報を取得
function getCookieFontSize() {
  let fontSizeValue = '';
  let cookies = document.cookie.split(';');

  for(let i = 0; i < cookies.length; i++) {
    let pear = cookies[i].split('=');
    if(pear[0].trim() == 'fontSize') {
      fontSizeValue = pear[1];
    }
  }
  return fontSizeValue;
}
