// トップにスクロールする処理
export function scrollTop () {
  let topButton = document.querySelector('aside img.pagescroll');

  // スクロールしたらボタンを表示
  window.onscroll = function() {
    let offSet = window.pageYOffset;
    if (!offSet) {
      offSet = document.documentElement.scrollTop;
    }

    if (!!offSet) {
      if (offSet > 150) {
        topButton.style.display = 'block';
      } else {
        topButton.style.display = 'none';
      }
    }
  }

  // ボタンをクリック
  if (!!topButton) {
    topButton.addEventListener('click', function(event) {
      window.scrollTo(0,0);
      topButton.style.display = 'none';
    });
  }
}